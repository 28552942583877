import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {siteActions} from "../../store/SiteSlice";
import './alert.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Alert = () => {
    const {title, message, level} = useSelector(state => state.site.alert)
    const dispatch = useDispatch();

    const handleCloseAlert = () => {
        dispatch(siteActions.removeAlert());
    }
    if (title) {
        return <div className="container">
            <div className={`alert ${level}`}>
                <div className="alert__body">
                    <div className="alert__title">{title}</div>
                    <div className="alert__message" dangerouslySetInnerHTML={{__html:message}}></div>
                </div>
                <div className="alert__close">
                    <button type="button" onClick={handleCloseAlert}>
                        <FontAwesomeIcon icon={["fas", "times"]}/>
                    </button>
                </div>
            </div>
        </div>;
    } else {
        return <></>;
    }
};

export default Alert;
