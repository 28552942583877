import axios from "axios";
import config from "./config";
import Store from '../store';
import {siteActions} from "../store/SiteSlice";

class Api {
    static async get(url, fullResponse = false) {
        Store.dispatch(siteActions.requestCountInc());
        return axios.get(config.API_URL + url, config.POST_HEADER)
            .then(async (response) => {
                Store.dispatch(siteActions.requestCountDec());

                if (fullResponse)
                    return response;
                if (response.data.error) {
                    if (typeof response.data.error === 'string') {
                        Store.dispatch(siteActions.setAlert({title: response.data.error, message: '', level: 'error'}));
                    } else {
                        Store.dispatch(siteActions.setAlert(response.data.error));
                    }
                }
                return response.data; // default response {success:1|0, data:[{},{}]|{},error:'' }
            })
            .catch((e) => {
                Store.dispatch(siteActions.requestCountDec());

                alert(e.message);
                return false;
            });
    }

    static async post(url, data, fullResponse = false, errorHandling = true) {
        Store.dispatch(siteActions.requestCountInc());

        return axios.post(config.API_URL + url, data, config.POST_HEADER)
            .then(async (response) => {
                Store.dispatch(siteActions.requestCountDec());

                if (fullResponse)
                    return response;
                if (response.data.error && errorHandling) {
                    if (typeof response.data.error === 'string') {
                        Store.dispatch(siteActions.setAlert({title: response.data.error, message: '', level: 'error'}));
                    } else {
                        Store.dispatch(siteActions.setAlert(response.data.error));
                    }
                }
                return response.data; // default response {success:1|0, data:[{},{}]|{},error:'' }
            })
            .catch((e) => {
                alert(e.message);
                Store.dispatch(siteActions.requestCountDec());
                return false;
            });
    }
}

export default Api;