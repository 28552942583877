import {Link} from "react-router-dom";
import config from "../core/config";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import TicketTypeModel from "../models/TicketType";
import TicketType from "../components/ticket_type/TicketType";
import SentVoucher from "../components/sent_voucher/SentVoucher";
import VoucherSend from "../components/voucher_send/VoucherSend";
import {T} from "../core/helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default () => {
    const params = useParams();
    const events = useSelector((state) => state.site.events);
    /**@type {Event}*/
    const event = events.find((item) => item.slug === params.slug) ?? false;

    const [selectedTicketType, setSelectedTicketType] = useState(false);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        if (event.id) {
            TicketTypeModel.get(event.id).then(result => {
                setTicketTypes(result);
            });
            event.getAttachments().then(result =>{
                setAttachments(result);
            });
        }
    }, [event]);

    if (event === false) {
        return <div></div>;
    }
    const eventDate = event.start_date + ' - ' + event.end_date.split(' ')[1];

    const handleSelectTicketType = (ticketType) => {
        if (selectedTicketType == ticketType.id) {
            setSelectedTicketType(false);
        } else {
            setSelectedTicketType(ticketType.id);
        }
    };

    // function saveFile(e, url) {
    //     e.preventDefault();
    //     // Get file name from url.
    //     let filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
    //     let xhr = new XMLHttpRequest();
    //     xhr.responseType = 'blob';
    //     xhr.onload = function() {
    //         let a = document.createElement('a');
    //         a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
    //         a.download = filename; // Set the file name.
    //         a.style.display = 'none';
    //         document.body.appendChild(a);
    //         a.click();
    //
    //     };
    //     xhr.open('GET', url);
    //     xhr.send();
    // }
    return <div>
        <div><Link to="/promoter" className="button mb-3">Vissza</Link></div>
        <div className="panel">
            <div className="event__page">
                <div className="row mb-4 event-head">
                    <div className="col-7 col-md-3">
                        <h1 className="event__title">
                            {event._name}
                        </h1>
                        <div className="event__detail">
                            <div>{eventDate}</div>
                            <div>{event.location_name}</div>
                            <div>{event.location_address}</div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {event.isActive() && <VoucherSend event={event}/>}
                    </div>
                </div>
                <div className="mb-4">
                    <TicketType ticketTypes={ticketTypes} selectedId={selectedTicketType}
                                handleSelectTicketType={handleSelectTicketType}/>
                </div>
                <hr className="hr-grey"/>
                <div>
                    {!!selectedTicketType && <SentVoucher eventId={event.id} selectedId={selectedTicketType}/>}
                </div>
                {attachments.length > 0 &&
                <div>
                    <h4 className="title">
                        {T("event_creatives")}
                    </h4>
                    <div>
                        {attachments.map((item, index) => <div key={"attachment_" + index}>
                            <FontAwesomeIcon className="attachment-fa" icon={["fas", "file"]}/><a className="attachment-link" href={config.API_DOMAIN + item.link}>{item.title}</a>
                        </div>)}
                    </div>
                </div>
                }
            </div>
        </div>
    </div>;
}
