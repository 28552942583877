import React, {useState} from "react";
import {T} from "../../core/helper";
import {Collapse} from "react-bootstrap";
import SentVoucherTicket from "./SentVoucherTicket";

export default ({voucher, handleDeleteVoucher,handleDeleteTicket}) => {
    const [open, setOpen] = useState(false);
    return <div className={"ticket-row " +(open?'active':'')}>
        <div className={"ticket-holder"} >
        <div className="row m-0 voucher-row" onClick={() => { setOpen(!open)}}>
            <div className="col-md-3"><span
                className="mobile"><strong>{T('ticket_type')}:</strong></span> {voucher.ticket_type_name}</div>
            <div className="col-md-3"><span
                className="mobile"><strong>{T('name')}:</strong></span> {voucher.recipient_name}</div>
            <div className="col-md-3"><span className="mobile"><strong>{T('email')}:</strong></span> {voucher.email}
            </div>
            <div className="col-md-3"><span
                className="mobile"><strong>{T('sent_quantity')}:</strong></span> {voucher.original_available_places}/{voucher.available_places}&nbsp;&nbsp;
                {voucher.status == 0 && <button onClick={() => handleDeleteVoucher(voucher)}>{T('delete')}</button>}
            </div>
        </div>

        {!!voucher.notDeletedTicketForPwa && <Collapse in={open}>
            <div>
            <div className="sent-voucher-tickets">
                <div className="row voucher-tickets-head">
                    <div className="desktop col-md-3">{T('validated_at')}</div>
                    <div className="desktop col-md-2">{T('is_paid')}</div>
                    <div className="desktop col-md-4">{T('email')}</div>
                    <div className="desktop col-md-3">{T('commission')}</div>
                </div>
                {voucher.notDeletedTicketForPwa.map((ticket, i) => <SentVoucherTicket key={voucher.id + "_" + ticket.id} ticket={ticket}/>)}
            </div>
            </div>
        </Collapse>}
        </div>
    </div>;
}
