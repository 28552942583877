import React from "react";
import config from "../../core/config";
import {Link} from "react-router-dom";

export default ({event}) => {

    return <div className="event__item">
        <Link to={"/promoter/event/" + event.slug}>
            {!!event.header_image && <img className="img-fluid desktop" src={config.API_DOMAIN + event.header_image} alt=""/>}
            <h3 className="event-title">{event._name}</h3>
            <p className="event-date">{event.start_date} - {event.end_date.split(' ')[1]}</p>
        </Link>
        <hr className="mobile-block hr-grey"/>
    </div>;
}
