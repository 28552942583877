import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {siteActions} from "./store/SiteSlice";
import Login from "./components/login/Login";
import Api from "./core/Api";
import User from "./models/User";
import Layout from "./pages/layout/Layout";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Index from "./pages/Index";
import Promoter from "./pages/Promoter";
import TicketCheck from "./pages/TicketCheck";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import Article from "./pages/Article";
import Event from "./pages/Event";
import EventModel from "./models/Event";
import Profile from "./pages/Profile";
import PerformanceReport from "./pages/PerformanceReport";
import AppSelectorLayout from "./pages/layout/AppSelectorLayout";
import ResetPassword from "./components/forgot-password/ResetPassword";
import Events from "./pages/Events";
import Transactions from "./pages/Transactions";
import config from "./core/config";

const showLoader = async () => {
    //Store.dispatch(siteActions.requestCountInc());
    //return new Promise(resolve => setTimeout(()=>{Store.dispatch(siteActions.requestCountDec());resolve(null);}, 0 ));
    return null;
}
const getRouter = (user) => {
    if (!!user) {
        const isPromoter = user.hasPermission('promoter');
        const isTicketController = user.hasPermission('ticket_controller');
        let routes = [];
        routes.push({
                element: <AppSelectorLayout/>, children: [
                    {path: '/', element: <Index/>, loader: showLoader},
                    {path: '*', element: <Index/>, loader: showLoader},
                ]
            }
        );
        if (isPromoter) {
            routes.push({
                element: <Layout/>, children: [
                    {path: '/promoter', element: <Promoter/>, loader: showLoader},
                    {path: '/promoter/performance-report', element: <PerformanceReport/>},
                    {path: '/promoter/inside-article/:slug', element: <Article/>, loader: showLoader},
                    {path: '/promoter/event/:slug', element: <Event/>, loader: showLoader},
                    {path: '/promoter/events', element: <Events/>, loader: showLoader},
                    {path: '/profile', element: <Profile/>},
                    {path: '/transactions/:type', element: <Transactions/>},
                ]
            });
        }
        if (isTicketController) {
            routes.push({
                element: <Layout/>, children: [
                    {path: '/profile', element: <Profile/>},
                    {path: '/check-in', element: <TicketCheck/>, loader: showLoader}
                ]
            });
        }
        return createBrowserRouter(routes);
    }

    return createBrowserRouter([
        {
            element: <Layout/>, children: [
                {path: '*', element: <Login/>, loader: showLoader},
                {path: '/forgot-password', element: <ForgotPassword/>, loader: showLoader},
                {path: '/reset-password', element: <ResetPassword/>, loader: showLoader},

            ]
        }
    ]);
};


export default () => {
    const user = useSelector(state => state.site.user);
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const init = async () => {
            await Api.get('init/index').then(result => {
                if (''+result.data.settings.pwa_version !== ''+config.APP_VERSION) {
                    caches.keys().then(async (cacheNames) => {
                        await Promise.all(
                            cacheNames.map((cacheName) => {
                                return caches.delete(cacheName);
                            })
                        );
                        if ('serviceWorker' in navigator) {
                            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                                for (const registration of registrations) {
                                    registration.unregister();
                                }
                                window.location.reload();
                            });
                        }else {
                            window.location.reload();
                        }
                    });
                }
                dispatch(siteActions.settings(result.data.settings));
            });
            await User.autoLogin().then((result) => {
                if (result) {
                    result.getPromoterData().then(_ => {
                        setInitialized(true);
                    });
                } else {
                    setInitialized(true);
                }
            });

        }
        init();
    }, []);

    useEffect(() => {
        if (user) {
            EventModel.getAll().then((futureEvents) => {
                EventModel.getAll(true).then((pastEvents) => {
                    dispatch(siteActions.events([...futureEvents, ...pastEvents]));
                });
            });
        }
    }, [user]);

    return <>
        {!!initialized && <RouterProvider router={getRouter(user)}/>}
    </>

}
