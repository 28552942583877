import React, {useEffect, useRef, useState} from "react";
import {T} from "../../core/helper";
import {useDispatch, useSelector} from "react-redux";
import Voucher from "../../models/Voucher";
import {siteActions} from "../../store/SiteSlice";
import TicketType from "../../models/TicketType";
import Event from "../../models/Event";

export default (props) => {
    const eventFromParent = props.event ?? false; // event oldalon is be van húzva ez a componens és olyankor megkapja az eventet
    /**@type {Event[]}*/
    const vouchers = useSelector(state => state.site.vouchers);

    const eventInputRef = useRef();
    const ticketTypeInputRef = useRef();
    const nameTypeInputRef = useRef();
    const emailTypeInputRef = useRef();
    const availablePlacesTypeInputRef = useRef();
    const clipboardRef = useRef();

    const [error, setError] = useState({});
    /** @type {TicketType[]} */
    const [ticketType, setTicketType] = useState([]);
    const [selectedIsAffiliate, setSelectedIsAffiliate] = useState(false);
    const [changeCounter, setChangeCounter] = useState(0);

    const [events, setEvents] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);

    const dispatch = useDispatch();
    let oldValue = '';

    useEffect(() => {
        const initPage = async () => {
            const [events, tickets] = await Promise.all([
                Event.getAll().then(result => {
                    return result
                }),
                TicketType.getAll().then(result => {
                    let _eventTicket = [];

                    for (let i = 0; i < result.length; i++) {
                        /** @type {TicketType}*/
                        let ticket = result[i];
                        if (ticket.isActive()) {
                            if (_eventTicket[ticket.event_id]) {
                                _eventTicket[ticket.event_id].push(ticket);
                            } else {
                                _eventTicket[ticket.event_id] = [];
                                _eventTicket[ticket.event_id].push(ticket);
                            }
                        }
                    }

                    return _eventTicket
                })
            ]);
            return {events: events, tickets: tickets}
        }
        initPage().then(result => {
            setEvents(result.events);
            setTicketTypes(result.tickets);

        });
    }, []);
    useEffect(() => {

        if (eventFromParent) {
            initSelectEvent(eventFromParent.id)
        } else {
            if (events.length) {
                for (let i = 0; i < events.length; i++) {
                    let event = events[i];
                    if (event.isActive() && ticketTypes[event.id] && ticketTypes[event.id].length > 0) {
                        initSelectEvent(event.id);
                        break;
                    }
                }
            }
        }
    }, [events, ticketTypes]);

    useEffect(() => {
        changeTicketType();
    }, [ticketType]);
    const initSelectEvent = (eventId) => {
        let filtered = ticketTypes[eventId];
        if (filtered) {
            let ticket = filtered[0];
            setSelectedIsAffiliate(!!ticket.affiliate);
        }
        setTicketType(filtered);
    }

    const handleSelectEvent = () => {
        let eventId = eventInputRef.current.value;
        let filtered = ticketTypes[eventId];
        setTicketType(filtered);
    }

    const handleSubmitVoucher = (e, copy = false) => {
        e.preventDefault();
        let eventId = eventInputRef?.current?.value;
        if (!eventId && eventFromParent.id) {
            eventId = eventFromParent.id;
        }
        let isValid = true;
        let error = {};
        if (eventId &&
            ticketTypeInputRef?.current?.value
        ) {
            let _ticketType = ticketType.find(item => +item.id === +ticketTypeInputRef.current.value);
            let voucher = new Voucher();
            voucher.event_id = eventId;
            voucher.ticket_type_id = ticketTypeInputRef.current.value;
            if (selectedIsAffiliate) {
                voucher.recipient_name = null;
                voucher.email = null;
                voucher.available_places = 1;
            } else {
                voucher.available_places = selectedIsAffiliate || availablePlacesTypeInputRef.current.value;
                if (!voucher.available_places) {
                    error = {...error, available_places: 'A mennyiség megadása kötelező'};
                    isValid = false;
                }
                voucher.recipient_name = selectedIsAffiliate || nameTypeInputRef.current.value ? nameTypeInputRef.current.value : null;
                voucher.email = selectedIsAffiliate || emailTypeInputRef.current.value ? emailTypeInputRef.current.value : null;
                if (!copy) {
                    voucher.recipient_name = selectedIsAffiliate || nameTypeInputRef.current.value ? nameTypeInputRef.current.value : null;
                    if (!voucher.recipient_name) {
                        error = {...error, recipient_name: 'A név megadása kötelező'};
                        isValid = false;
                    }
                    voucher.email = selectedIsAffiliate || emailTypeInputRef.current.value ? emailTypeInputRef.current.value : null;
                    if (!voucher.email) {
                        error = {...error, email: 'Az email megadása kötelező'};
                        isValid = false;
                    }
                }
            }
            if (!isValid) {
                setError(error);
                return;
            }
            voucher.ticket_type_name = _ticketType._name;
            voucher.is_copy = copy;

            voucher.send().then(result => {
                if (result.success) {
                    if (result.link && copy) {
                        handleCopyLink(result.link);
                    }
                    if (!selectedIsAffiliate) {
                        nameTypeInputRef.current.value = '';
                        emailTypeInputRef.current.value = '';
                        availablePlacesTypeInputRef.current.value = '';
                    }

                    if (vouchers.selectedId == ticketTypeInputRef.current.value) {
                        let newVouchers = {
                            selectedId: vouchers.selectedId,
                            vouchers: [...vouchers.vouchers, Voucher.loadNew(result.voucher)]
                        };
                        dispatch(siteActions.vouchers(newVouchers));
                    }
                    if (!(result.link && copy)) {
                        dispatch(siteActions.setAlert({title: 'Sikeres küldés', message: '', level: 'success'}));
                    }
                    setError({});
                } else {
                    dispatch(siteActions.setAlert({title: 'Hiba', message: result.error, level: 'error'}));
                }
            });

        } else {
            dispatch(siteActions.setAlert({
                title: 'Hiba',
                message: 'Nincs kiválasztott Esemény vagy jegytípus',
                level: 'error'
            }));
        }
    }

    const openShareMenu = async (link) => {
        try {
            await navigator.share({
                url: link,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    const handleCopyLink = (link) => {
        openShareMenu(link).then(result => {
            console.log(result);

            // if (!result) {
            //     //sajnálom
            //     clipboardRef.current.value = link;
            //     let copyText = document.getElementById("clipboard");
            //     copyText.select();
            //     copyText.setSelectionRange(0, 99999);
            //     navigator.clipboard.writeText(copyText.value);
            //     dispatch(siteActions.setAlert({title: 'Link:', message: link, level: 'success'}))
            // }
        });
    }
    const copyLink = (e) => {
        handleSubmitVoucher(e, true);
    }
    const validateNumberInput = (e) => {
        let $numberArray = [8, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
            96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
        if (!$numberArray.includes(e.which)) {
            if (!$numberArray.includes(e.keyCode)) {
                e.preventDefault();
            }
        }
    }
    const handleNumberInputKeyUp = (e) => {
        const globalRegex = new RegExp('^[0-9]*$', 'g');
        if (globalRegex.test(e.target.value)) {
            oldValue = availablePlacesTypeInputRef.current.value;
        } else {
            availablePlacesTypeInputRef.current.value = oldValue;
        }
    }
    const changeTicketType = () => {
        if (ticketTypeInputRef?.current?.value && ticketTypes) {
            let selected = ticketType.find(item => item.id == ticketTypeInputRef.current.value);
            setSelectedIsAffiliate(selected.affiliate);
        }
    };
    const getSendButtonClassName = () => {
        return nameTypeInputRef.current?.value &&
        emailTypeInputRef.current?.value &&
        availablePlacesTypeInputRef.current?.value ? '' : 'btn-disabled';
    }
    const triggerChange = () => {
        setChangeCounter(changeCounter + 1);
    }

    if (!(!!ticketTypes && !!events && !!ticketType)) {
        return <></>;
    }
    return <form onSubmit={handleSubmitVoucher}>
        <div className="voucher__send d-lg-flex mt-lg-0 mt-4">

            {!eventFromParent && <div className="input-block">
                <label>{T('event')}</label>
                <select ref={eventInputRef} defaultValue={eventFromParent.id} onChange={handleSelectEvent}>
                    {!!events && events.filter(event => (event.isActive() && ticketTypes[event.id] && ticketTypes[event.id].length > 0))
                        .map((event, i) => <option key={i + "event-select-option"}
                                                   value={event.id}>{event._name}</option>)}
                </select>
                <small className="error">{error['event']}</small>
            </div>}
            <div className="input-block">
                <label>{T('ticket_type')}</label>
                <select ref={ticketTypeInputRef} onChange={changeTicketType}>
                    {!!ticketType && ticketType.filter(_ticketType => _ticketType.isActive()).map((_ticketType, i) =>
                        <option key={i + "ticket-select-option"}
                                value={_ticketType.id}>{_ticketType._name}</option>)}
                </select>
                <small className="error">{error['event']}</small>

            </div>
            {!selectedIsAffiliate &&
            <>
                <div className="input-block">
                    <label>{T('name')}</label>
                    <input ref={nameTypeInputRef} type="text" onBlur={triggerChange}/>
                    <small className="error">{error['recipient_name']}</small>

                </div>

                <div className="input-block">
                    <label>{T('email')}</label>
                    <input ref={emailTypeInputRef} type="email" onBlur={triggerChange}/>
                    <small className="error">{error['email']}</small>

                </div>

                <div className="input-block">
                    <label>{T('quantity')}</label>
                    <input onKeyDown={validateNumberInput} onKeyUp={handleNumberInputKeyUp} onBlur={triggerChange}
                           ref={availablePlacesTypeInputRef}/>
                    <small className="error">{error['available_places']}</small>

                </div>
            </>
            }
            <div className="d-flex mt-3 mt-lg-0 gap-2">
                <button type="button" onClick={copyLink}>Link</button>
                {!selectedIsAffiliate &&
                <button type="submit" className={getSendButtonClassName()}>Küldés</button>}
            </div>
            <input ref={clipboardRef} id="clipboard"
                   style={{display: 'none', height: 0, width: 0, position: 'absolute'}}/>
        </div>
    </form>;
}
