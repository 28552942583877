import {T} from "../core/helper";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import EventList from "./partials/EventList";
import React, {useEffect, useState} from "react";
import ArticleList from "./partials/ArticleList";
import VoucherSend from "../components/voucher_send/VoucherSend";

export default () => {
    /** @type {User} */
    const user = useSelector(state => state.site.user);
    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        user.getPromoterData().then((_) => {
            setIsInitialized(true);
        });
    }, []);

    if (!isInitialized) {
        return <></>;
    }
    return <div className="panel promoter">
        <div className="row">
            <div className="col-md-6">
                <h1 className="title">Szia {user._profile.name}!</h1>
                <div className="d-flex flex-wrap gap-2">
                    <Link className="button" to="/transactions/own">
                            <span className="me-3">
                                {T('balance')}: <strong>{user.balance || 0} Ft</strong>
                            </span>
                    </Link>
                    {(!!user.balance_child || user.has_child_promoter) &&
                    <Link className="button" to="/transactions/child">
                            <span className="me-3">
                                {T('child_promoter_balance')}: <strong>{user.child_balance || 0} Ft</strong>
                            </span>
                    </Link>
                    }
                    <div>
                        <Link className="button" to={'/promoter/performance-report'}>Teljesítmény riport</Link>
                    </div>
                </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end">

            </div>
        </div>
        <h2 className="title mt-3">{T('fast_list')}</h2>
        <div className="mb-5">
            <VoucherSend/>
        </div>
        <EventList/>
        <div className="text-center mb-4 mt-2"><Link to="/promoter/events" className="button">Lezajlott események</Link>
        </div>
        <div className="mb-5">
            <ArticleList/>
        </div>
    </div>;
}
