import React from "react";
import {T} from "../../core/helper";
import Voucher from "../../models/Voucher";
import {siteActions} from "../../store/SiteSlice";
import {useDispatch} from "react-redux";

/**
 * @param {object} param
 * @param {TicketType} param.ticket
 * @param {integer} param.selectedId
 * @param {function} param.handleSelectTicketType
 * */
export default ({ticket, selectedId, handleSelectTicketType}) => {
    const dispatch = useDispatch();

    const sendLinkRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let voucher = new Voucher();
        voucher.event_id = ticket.event_id;
        voucher.ticket_type_id = ticket.id;
        voucher.available_places = 0;
        voucher.ticket_type_name = ticket._name;
        voucher.is_copy = true;

        voucher.send().then(result => {
            if (result.success) {
                if (result.link) {
                    handleCopyLink(result.link);
                }
            } else {
                dispatch(siteActions.setAlert({title: 'Hiba', message: result.error, level: 'error'}));
            }
        });

    }
    const openShareMenu = async (link) => {
        try {
            await navigator.share({
                url: link,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    const handleCopyLink = (link) => {
        openShareMenu(link).then(result => {
            // if (!result) {
            //     let copyText = document.getElementById("clipboard");
            //     copyText.value = result.link;
            //     copyText.select();
            //     copyText.setSelectionRange(0, 99999);
            //     navigator.clipboard.writeText(copyText.value);
            //     dispatch(siteActions.setAlert({title: 'Link: ' + result.link, message: '', level: 'success'}));
            // }
        });
    }

    return <div className={`row ticket-row ${selectedId === ticket.id ? 'selected' : ''}`} onClick={() => {
        handleSelectTicketType(ticket)
    }}>
        <div className="col-md-2"><span
            className="mobile"><strong>{T('ticket_type')}:</strong></span> {ticket._name} {!!ticket.affiliate && ticket.isActive() &&
        <button onClick={(e) => {
            sendLinkRequest(e)
        }}>Link másolása</button>}</div>
        <div className="col-md-2"><span className="mobile"><strong>{T('price')}:</strong></span> {ticket.gross_price}
        </div>
        <div className="col-md-2"><span className="mobile"><strong>{T('commission')}:</strong></span> {ticket.comission}
        </div>
        <div className="col-md-2"><span
            className="mobile"><strong>{T('available')}:</strong></span> {ticket.available_places}</div>
        <div className="col-md-4"><span
            className="mobile"><strong>{T('description')}:</strong></span> {ticket.type_description}</div>
    </div>
}
