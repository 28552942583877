import React, {useEffect, useState} from "react";
import Event from "../models/Event";
import EventItem from "./partials/EventItem";
import {Link} from "react-router-dom";
import Pagination from "../components/pagination/Pagination";

export default (props) => {

    const [events, setEvents] = useState([]);
    const [eventsOnPage, setEventsOnPage] = useState([]);
    useEffect(() => {
        Event.getAll(true).then(events => {
            setEvents(events);
        })

    }, []);

    const handlePagination = (from, to) => {
        //Lapozóhoz kell ezt a függvényt hívja meg lapozáskor és ez fogja beállítani az adott oldalon lévő cikkeket
        let _eventsOnPage = events.slice(from, to);
        setEventsOnPage(_eventsOnPage);
        window.scrollTo(0, 0);
    }

    return <>
        <div><Link to="/promoter" className="button mb-3">Vissza</Link></div>

        <div className="panel">
            <h2 className="title mb-3">Elmult események</h2>
            <div className="row">
                {!!eventsOnPage && eventsOnPage.map((event, index) => <div key={index + "_event"} className="col-sm-3"><EventItem
                    event={event}/></div>)}
            </div>
            <Pagination setItem={handlePagination} count={events.length} itemOnPage={12}/>
        </div>
    </>;
}