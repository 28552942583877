import React from "react";
import {T} from "../../core/helper";


export default ({ticket}) => {

    return <div className="row voucher-ticket-row">

        <div className="col-md-3">
            <span className="mobile">
                <strong>{T('validated_at')}: </strong>
            </span>
            {ticket.used_at || T('no')}
        </div>
        <div className="col-md-2">
            <span className="mobile">
                <strong>{T('is_paid')}: </strong>
            </span>
            {ticket.paid ? T('yes') : T('no')}

        </div>
        <div className="col-md-4">
            <span className="mobile">
                <strong>{T('email')}: </strong>
            </span>
            {ticket.user_email}

        </div>
        <div className="col-md-3">
            <span className="mobile">
                <strong>{T('commission')}: </strong>
            </span>
            {ticket.amount}
        </div>

    </div>;
}
