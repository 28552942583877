import React, {useEffect, useState} from "react";
import Article from "../../models/Article";
import ArticleItem from "./ArticleItem";


export default () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        Article.getAll().then((_articles) => {
            setArticles(_articles);
        });
        return () => { setArticles([])};
    }, []);

    return <div className="row">
        {!!articles && articles.map((article, index) => <div key={index+"_article"} className="col-sm-4"><ArticleItem article={article}/></div>)}
    </div>;
}