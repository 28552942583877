import Model from "../core/model";
import Api from "../core/Api";
import Store from "../store";
import {siteActions} from "../store/SiteSlice";
import config from "../core/config";

/**
 * @namespace
 * @property {number} id
 * @property {string} api_token
 * @property {number} balance
 * @property {boolean} has_child_promoter
 * @property {string} knowledge_level
 * @property {number} child_balance
 * @property {JSX} qr_code
 * @property {string} promoter_image
 * @property {object} _profile
 * @property {object} _address
 * @property {string} parentPromoter
 * @property {array} children
 *
 * */

class User extends Model {
    constructor() {
        super();
        this.user_id = false;
        this.api_token = false;
        this.password = '';
        this.role = [];
        this.user_id = false;
        this.api_token = false;
    }

    static loginUrl = 'user/login';
    static logoutUrl = 'user/logout';
    static validateUrl = 'user/validate';
    static promoterUrl = 'user/promoter';
    static forgotPasswordUrl = 'user/forgotten-password';
    static resetPasswordUrl = 'user/reset-password';

    //belépéskor lekérdezi az user adatait, és berakja a localstorage-be
    async login() {
        let data = await Api.post(User.loginUrl, this);
        if (data.error) {
            return false;
        }
        window.localStorage.setItem('user', JSON.stringify({user_id: data.user.id, api_token: data.user.api_token}));
        let user = User.loadNew(data.user);
        await user.getPromoterData();
        Store.dispatch(siteActions.user(user));
        return user;
    }

    static async autoLogin() {
        //megprbáljuk local storage-ből lekérni az adatokat
        let data = JSON.parse(window.localStorage.getItem('user'));
        if (data) {
            let result = await Api.post(User.validateUrl, data);
            if (result.isValid) {
                let user = User.loadNew(result.user);
                await user.getPromoterData();

                Store.dispatch(siteActions.user(user));
                return user;

            } else {
                window.localStorage.removeItem('user');
            }
        }
        return false;
    }

    static refresh(user) {
        Store.dispatch(siteActions.user(user));
    }

    async logout() {
        this.user_id=this.id;
        let data = await Api.post(User.logoutUrl, this);
        if (data.error) {
            return false;
        }
        window.localStorage.removeItem('user');
        Store.dispatch(siteActions.user(false));
    }

    static async isLoggedIn() {
        let user = window.localStorage.getItem('user');
        if (user) {
            let result = await Api.post(User.validateUrl, user);
            return result.isValid;
        }
        return false;
    }

    async getPromoterData() {
        let promoter = await Api.post(User.promoterUrl, {user_id: this.id, api_token: this.api_token});
        this.load(promoter);
        Store.dispatch(siteActions.user(this));
        return true;
    }

    hasPermission(role) {
        return this.role.includes(role);
    }

    static get() {
        return Store.getState().site.user;
    }

    static async forgotPassword(username) {
        const resetLink = config.APP_DOMAIN + '/reset-password';
        let result = await Api.post(User.forgotPasswordUrl, {username: username, reset_link: resetLink});
        if (result.success)
            Store.dispatch(siteActions.setAlert({title: 'Email sikeresen kiküldve', level: 'success'}));
        else {
            Store.dispatch(siteActions.setAlert({title: result.error, level: 'error'}));
        }
    }

    static async resetPassword(token, password) {
        let result = await Api.post(User.resetPasswordUrl, {reset_token: token, password: password});
        if (result.success)
            Store.dispatch(siteActions.setAlert({title: 'Jelszó sikeresen módosítva', level: 'success'}));
        else {
            Store.dispatch(siteActions.setAlert({title: result.error, level: 'error'}));
        }
        return result.success;
    }

    /**
     * A db-ben nem csak egy int van tárolva hanem teljes dátum itt viszont csak az évszám kell ezért le kell vágni az évszám utáni részt
     * @return integer
     * */
    getSchoolStartYear() {
        let initSchoolStartYear = null;
        if (this._profile.school_start_year) {
            initSchoolStartYear = this._profile.school_start_year.split('-')[0];
        }
        return initSchoolStartYear;
    }

    hasChildPromoter(){
        return !!this.has_child_promoter;
    }
}

export default User;
