import model from "../core/model";
import Store from "../store";
import Api from "../core/Api";
import {Timestamp} from "../core/helper";

/**
 * @property id {string}
 * @property name {string}
 * @property active {integer}
 * @property affiliate {integer}
 * @property available_places {string}
 * @property usedPlaces {string}
 * @property commission {string}
 * @property email_text {string}
 * @property start_check_in {string}
 * @property end_check_in {string}
 * @property start_sale {string}
 * @property end_sale {string}
 * @property event_id {string}
 * @property gross_price {string}
 * @property image {string}
 * @property purchase_type {string}
 * @property service_fee {string}
 * @property tax {string}
 * @property ticket_text {string}
 * @property type_description {string}
 * */
class TicketType extends model {
    constructor() {
        super();
        this._name = false;
    }
    static getUrl = 'event/ticket-type';


    static async get(eventId){
        let user = Store.getState().site.user;
        let result = await Api.post(TicketType.getUrl, {user_id:user.id, api_token:user.api_token, event_id:eventId});
        let ticketTypes = [];
        for(const ticket_type of result.ticket_type){
            ticketTypes.push(TicketType.loadNew(ticket_type));
        }
        return ticketTypes;
    }
    static async getAll(){
        let user = Store.getState().site.user;
        let result = await Api.post(TicketType.getUrl, {user_id:user.id, api_token:user.api_token});
        let ticketTypes = [];
        for(const ticket_type of result.ticket_type){
            ticketTypes.push(TicketType.loadNew(ticket_type));
        }
        return ticketTypes;
    }
    /**
     * @return boolean
     * */
    isActive(){
        if(Timestamp(this.start_sale) <= Timestamp() && Timestamp(this.end_sale) >= Timestamp()){
            if(+this.available_places > +this.usedPlaces || this.affiliate)
            return true;
        }
        return false;
    }



}
export default TicketType;
