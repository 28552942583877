import React from "react";
import logo from "../../style/img/logo.png";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import User from "../../models/User";
import config from "../../core/config";

export default (props)=>{
    const user = useSelector(store=>store.site.user);
    const handleLogout = () => {
        let user = User.get();
        user.logout();
    }

    return <nav className="header">
        <div className="container my-navbar">
            <div className="logo">
                <Link to="/">
                <img src={logo} alt=""/>
                </Link>
            </div>
            {!!user && <div>
                <Dropdown>
                    <Dropdown.Toggle className="nav-button">
                        {user._profile.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Link className="dropdown-item" to="/profile">Profil</Link>
                        <button className="dropdown-item" onClick={handleLogout}>Kijelentkezés</button>
                        <div style={{textAlign:'right',paddingRight:'10px'}}><small style={{color:'#aaaaaa'}}>{config.APP_VERSION}</small></div>
                    </Dropdown.Menu>
                </Dropdown>

            </div>}
        </div>

    </nav>;
}
