import React from "react";
import {T} from "../../core/helper";
import {Link} from "react-router-dom";
import config from "../../core/config";

export default ({article}) => {

    return <div className="article__item">
        <h3 className="title">{article.title}</h3>
        {!!article.image && <img className="img-fluid" src={config.API_DOMAIN + article.image} alt=""/>}
        <div className="short" dangerouslySetInnerHTML={{__html: article.short}}></div>
        <div className="text-start"><Link className="button" to={'/promoter/inside-article/' + article.slug}>{T('more')}</Link></div>
    </div>;
}