import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {T} from "../core/helper";
import useInput from "../components/input/Input";
import useSelect from "../components/input/Select";
import User from "../models/User";
import Api from "../core/Api";
import Pagination from "../components/pagination/Pagination";
import {Link} from "react-router-dom";

export default () => {
    const params = useParams();

    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 3);

    let endDate = new Date()
    endDate.setDate(endDate.getDate() + 1);

    let yyyystart = startDate.getFullYear();
    let mmstart = startDate.getMonth() + 1;
    let ddstart = startDate.getDate();

    let yyyyend = endDate.getFullYear();
    let mmend = endDate.getMonth() + 1;
    let ddend = endDate.getDate();

    if (ddstart < 10) ddstart = '0' + ddstart;
    if (mmstart < 10) mmstart = '0' + mmstart;

    if (ddend < 10) ddend = '0' + ddend;
    if (mmend < 10) mmend = '0' + mmend;

    const [transactions, setTransactions] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const dateFromInput = useInput({
        id: 'date_from_input',
        showLabel: false,
        type: "date",
        value: `${yyyystart}-${mmstart}-${ddstart}`
    });
    const dateToInput = useInput({
        id: 'date_to_input',
        showLabel: false,
        type: "date",
        value: `${yyyyend}-${mmend}-${ddend}`
    });
    const paidSelect = useSelect({
        id: 'paid_select',
        showLabel: false,
        options: [{key: '', value: T('please_select')},
            {key: 1, value: T('paid')},
            {key: 0, value: T('not_paid')}
        ]
    });
    const filterData = async (page = 0) => {
        let user = User.get();
        let data = {
            user_id: user.id,
            api_token: user.api_token,
            child_commission: params.type == 'child',
            date_from: dateFromInput.ref.current.value??null,
            date_to: dateToInput.ref.current.value,
            page: page,
            count_on_page: 10,
        };
        if (paidSelect.ref.current.value != '')
            data.is_paid = paidSelect.ref.current.value;
        let result = await Api.post('user/transactions', data);
        if (result) {
            setTransactions(result.transactions);
            setPageCount(result.page_count);
        }
    }

    useEffect(() => {
        filterData();
    }, []);

    const handleFilter = (e) => {
        e.preventDefault();
        let isValid = dateFromInput.jsx;
        isValid = dateToInput.jsx && isValid;
        isValid = paidSelect.jsx && isValid;
        if (isValid) {
            filterData();
        }
    }

    const handlePaginate = (from, to, page) => {
        filterData(page - 1);
    }
    return <div>
        <div><Link to="/promoter" className="button mb-3">Vissza</Link></div>

        <div className="panel mb-4">
            <h1 className="title">{params.type == 'own' ? T('balance') : T('child_promoter_balance')}</h1>

            <form onSubmit={handleFilter}>
                {dateFromInput.jsx}
                {dateToInput.jsx}
                {paidSelect.jsx}
                <button type="submit">Szűrés</button>
            </form>
        </div>
        <div className="panel">
            <div className={"panel performance-grid grid-col-4"}>
                <div className="desktop performance-grid__header">Összeg</div>
                <div className="desktop performance-grid__header">Kifizetés</div>
                <div className="desktop performance-grid__header">Dátum</div>
                <div className="desktop performance-grid__header">Megjegyzés</div>
                {!transactions && <p>Nincs Tranzakció</p>}

                {transactions.map((transaction, index) => <React.Fragment key={index}>
                        <div>
                            <span className="mobile"><strong>Összeg: </strong></span>
                            {transaction.amount}</div>
                        <div>
                            <span className="mobile"><strong>Kifizetés: </strong></span>
                            {transaction.payment ? T('paid') : T('not_paid')}</div>
                        <div>
                            <span className="mobile"><strong>Dátum: </strong></span>
                            {transaction.date}</div>
                        <div>
                            <span className="mobile"><strong>Megjegyzés: </strong></span>
                            {transaction.comment}</div>
                        <hr className="hr-grey mobile-block"/>
                    </React.Fragment>
                )}
            </div>

            <Pagination setItem={handlePaginate} itemOnPage={10} count={pageCount * 10}/>
        </div>

    </div>;
}
