import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Article from "../models/Article";
import {Link} from "react-router-dom";

export default (props) => {
    const params = useParams();
    const [article, setArticle] = useState(false);

    useEffect(() => {
        Article.getBySlug(params.slug).then((_article) => {
            setArticle(_article);
        });
    }, [params]);

    if (article === false) {
        return <div></div>;
    }
    return <div>
        <div><Link to="/promoter" className="button mb-3">Vissza</Link></div>

        <div className="panel">
        <div className="article__page">
            <h1>
                {article.title}
            </h1>
            <div dangerouslySetInnerHTML={{__html: article.short}}></div>
            <div dangerouslySetInnerHTML={{__html: article.text}}></div>
        </div>
    </div>
    </div>;
}