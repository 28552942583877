import Api from "../core/Api";
import User from "../models/User";
import config from "../core/config";
import {QrReader} from 'react-qr-reader';
import React, {useEffect, useState} from "react";
import EventList from "./checkin_partials/EventList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default (props) => {
    const phases = {
        SELECT_EVENT: 0,
        READ: 1,
        SHOW_DATA: 2,
        SHOW_PROMOTER: 3,
        SHOW_LOG: 4
    }
    const [phase, setPhase] = useState(phases.SELECT_EVENT);
    const [QRCode, setQRCode] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [qrResult, setQrResult] = useState(false);
    const [logData, setLogData] = useState(false);

    useEffect(() => {
        if (!!QRCode) {
            sendQRCode();
        }
    }, [QRCode]);

    const sendQRCode = async () => {
        setPhase(phases.SHOW_DATA);
        let user = User.get();
        let result = await Api.post('checkin/scan-qr-code', {
            user_id: user.id,
            api_token: user.api_token,
            code: QRCode,
            event_id: selectedEvent.id
        },false,false);

        if (!!result.promoter) {
            setPhase(phases.SHOW_PROMOTER);
            setQrResult(result);
            return;
        }
        setQrResult(result);

    };

    const handleReadQRCode = async (qr, error) => {
        if (QRCode) {
            return false;
        }
        if (!!qr && phase === phases.READ) {
            let qrCode = qr?.text;
            if (qrCode) {
                setQRCode(qrCode);
            }
        }
    };
    // módosítja a qr kódot, ami triggereli az useEffect-et
    const handlePay = async () => {
        let user = User.get();

        await Api.post('checkin/pay', {
            user_id: user.id,
            api_token: user.api_token,
            code: QRCode,
            event_id: selectedEvent.id
        });
        handleReset();

    };
    const handleEnter = async () => {
        let user = User.get();
        await Api.post('checkin/enter', {
            user_id: user.id,
            api_token: user.api_token,
            qr_code: QRCode,
            event_id: selectedEvent.id
        });
        handleReset();
    }
    const handleSelectEvent = (_selectedEvent) => {
        setSelectedEvent(_selectedEvent);
        setPhase(phases.READ);
    }
    // törli a beolvasott adatokat és visszalép a qr kód olvasóhoz
    const handleReset = () => {
        setQRCode(null);
        setQrResult(false);
        setPhase(phases.READ);

    }

    // a phase határozza meg hogy éppen melyik része látszik az alkalmazásnak, ha a vissza gombra nyomunk, akkor itt csökkentjük az értékét
    const goBack = () => {
        handleReset();
        if(phase <= phases.READ){
            setPhase(phases.SELECT_EVENT);
        }
    }
    const handleShowLog = async () => {
        let user = User.get();

        let result = await Api.post('checkin/log', {
            user_id: user.id,
            api_token: user.api_token,
            event_id: selectedEvent.id
        });
        setLogData(result.ticket);
        setPhase(phases.SHOW_LOG);
    }
    return <div className="check-in">
        <div className="d-flex justify-content-between">
            {phase > phases.SELECT_EVENT && <button className="mb-3" onClick={goBack}>Vissza</button>}
            {phase == phases.READ && <button className="mb-3" onClick={handleShowLog}>Log</button>}
        </div>
        {phase === phases.SELECT_EVENT && <div className="checkin-events"><EventList ticketCheck handleSelectEvent={handleSelectEvent}/></div>}
        {phase === phases.READ &&
        <>
            <QrReader onResult={handleReadQRCode} className="qr-reader" constraints={{
                facingMode: 'environment'
            }}/>
            <div>
                <div> {!!selectedEvent && <h4 className="title">{selectedEvent._name}</h4>}</div>
                <div> {!!selectedEvent && <span className="title"><strong>Dátum: </strong>{selectedEvent.start_date} - {selectedEvent.end_date}</span>}</div>
                <div> {!!selectedEvent && <span className="title"><strong>Kapunyitás: </strong>{selectedEvent.gate_opening_time}</span>}</div>
                <div> {!!selectedEvent && <span className="title"><strong>Cím: </strong>{selectedEvent.location_address}</span>}</div>
            </div>
        </>
        }
        {phase === phases.SHOW_DATA && qrResult && <>
            <div className="container">
                <div className={`alert alert-checkin `+(qrResult.isValid && qrResult.isPaid ? 'success' : (qrResult.isValid && !qrResult.isPaid ? 'warning' : 'error'))}>
                    <div className="alert__body">
                        <div className="alert__title">{(qrResult.isValid && qrResult.isPaid ? 'Érvényes' : (qrResult.isValid && !qrResult.isPaid ? 'Fizetési kötelezettség '+qrResult.ticketType.gross_price+'Ft' : 'Érvénytelen'))}</div>
                        <div className="alert__message">{qrResult.error}</div>
                    </div>
                </div>
            </div>

            <div className="ticket-data">
                {!!qrResult.ticketType && <><p><span className="title">Ticket:</span> {qrResult.ticketType.name}</p>
                <p><span className="title">Érvényes:</span> {qrResult.isValid ? 'Igen' : 'Nem'}</p>
                <p><span className="title">Fizetve:</span> {qrResult.isPaid ? 'Igen' : 'Nem'}</p>
                <p><span className="title">Ár:</span> {qrResult.ticketType.gross_price} Ft</p>
                <p><span
                    className="title">Fizetési mód:</span> {qrResult.paymentType}
                </p></>}
                {!qrResult.ticketType && <h4 className="title">JEGY NEM TALÁLHATÓ</h4>}
            </div>

            <div style={{flexGrow: 1}}>
            <div className="ticket-button">
                {!!qrResult.isValid && !qrResult.isPaid && <button className="mb-3 button-md" onClick={handlePay}>Fizetve és beengedve<FontAwesomeIcon className="checkin-fa-right" icon={["fas", "angle-right"]}/></button>}
            </div>
            <div className="ticket-button">
                {!!qrResult.isValid && qrResult.isPaid && <button className="mb-3 button-md" onClick={handleEnter}>Beenged<FontAwesomeIcon className="checkin-fa-right" icon={["fas", "angle-right"]}/></button>}
            </div>
            <div className="ticket-button">
                <button className="button-md" onClick={handleReset}><FontAwesomeIcon className="checkin-fa-left" icon={["fas", "angle-left"]}/>Új jegy beolvasása</button>
            </div>
            </div>
        </>}

        {phase === phases.SHOW_PROMOTER && !!qrResult.promoter && <>
            <img className="img-fluid" src={config.API_DOMAIN + qrResult.promoter.user_image} alt="promoter img"/>
            <div className="ticket-data">
                <p><span className="title">Név:</span> {qrResult.promoter._profile.last_name} {qrResult.promoter._profile.first_name}</p>
                <p><span className="title">Érvényes:</span> {qrResult.isPromoter && !qrResult.alreadyIn ? 'Igen' : 'Nem'}</p>
                <p><span className="title">Már belépett:</span> {qrResult.alreadyIn ? 'Igen' : 'Nem'}</p>
                {!qrResult.isPromoter && <p><span className="title">A promoter nincs az eseményhez rendelve!</span></p>}
            </div>
            <div className="ticket-button">
                <button className={"button-md"} onClick={handleReset}><FontAwesomeIcon className="checkin-fa-left" icon={["fas", "angle-left"]}/>Új jegy beolvasása</button>
            </div>
        </>}
        {phase === phases.SHOW_LOG && <div className="panel grid">
            <div className="grid__header">
                Összesen felhasznált jegy: {logData.length}
            </div>
            <div className="row">
                <div className="desktop col-sm-3 grid__header">Jegy neve:</div>
                <div className="desktop col-sm-3 grid__header">Ár:</div>
                <div className="desktop col-sm-3 grid__header">Ellenőrizte:</div>
                <div className="desktop col-sm-3 grid__header">Ellenőrizve:</div>
            </div>
            {logData.map((logRow, index)=><div key={index} className="row">
                <div className="col-sm-3"><span className="mobile"><strong>Jegy neve: </strong></span>{logRow.ticketType.name}</div>
                <div className="col-sm-3"><span className="mobile"><strong>Ár: </strong></span>{logRow.gross_price}</div>
                <div className="col-sm-3"><span className="mobile"><strong>Ellenőrizte: </strong></span>{logRow.used_by}</div>
                <div className="col-sm-3"><span className="mobile"><strong>Ellenőrizve: </strong></span>{logRow.used_at}</div>
                <hr className="hr-grey mobile-block"/>
            </div>)}
        </div>}
    </div>
}
