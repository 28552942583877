import React, {useEffect, useRef, useState} from "react";
import Voucher from "../../models/Voucher";
import {T} from "../../core/helper";
import SentRow from "./SentRow";
import Collapse from 'react-bootstrap/Collapse';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {siteActions} from "../../store/SiteSlice";
import Api from "../../core/Api";
import User from "../../models/User";

export default ({eventId, selectedId}) => {
    const vouchers = useSelector(state => state.site.vouchers);
    const [filteredVouchers, setFilteredVouchers] = useState([vouchers.vouchers]);
    const [open, setOpen] = useState(false);
    const nameRef = useRef();
    const emailRef = useRef();
    const dispatch = useDispatch();
    useEffect(() => {
        Voucher.get(eventId, selectedId).then(_vouchers => {
            dispatch(siteActions.vouchers({selectedId: selectedId, vouchers: _vouchers}));
            setFilteredVouchers(_vouchers);
        });
        if (nameRef) nameRef.current.value = '';
        if (emailRef) emailRef.current.value = '';
    }, [selectedId, eventId]);

    useEffect(() => {
        setFilteredVouchers(vouchers.vouchers);
    }, [vouchers]);

    const handleNameInput = (e) => {
        let value = e.target.value;
        let filtered = vouchers.vouchers.filter(item => item.recipient_name.search(value) >= 0)
        setFilteredVouchers(filtered);
    }
    const handleEmailInput = (e) => {
        let value = e.target.value;
        let filtered = vouchers.vouchers.filter(item => item.email.search(value) >= 0)
        setFilteredVouchers(filtered);
    }

    const handleDeleteVoucher = (voucher) => {
        if (window.confirm('Biztosan törli a vouchert?')) {
            voucher.delete();
            //frissíti a kiküldött voucherek listáját
            let _vouchers = [...vouchers.vouchers];
            let index = vouchers.vouchers.findIndex(item => item.id == voucher.id);
            _vouchers.splice(index, 1);
            let newVouchers = {selectedId: vouchers.selectedId, vouchers: [..._vouchers]};
            dispatch(siteActions.vouchers(newVouchers));
        }
    }
    const handleDeleteTicket = (voucherId,ticketUniqueId) => {
        if (window.confirm('Biztosan törli a Jegyet?')) {
            let user = User.get();
            Api.post('ticket/delete',{user_id:user.id,api_token:user.api_token,unique_id:ticketUniqueId}).then(result=>{
               if(result.success){
                   let _vouchers = [...vouchers.vouchers];
                   let voucherIndex = vouchers.vouchers.findIndex(item => item.id == voucherId);
                   let ticketIndex = _vouchers[voucherIndex].notDeletedTicket.findIndex(item=>item.unique_id = ticketUniqueId);
                   _vouchers[voucherIndex].notDeletedTicket.splice(ticketIndex, 1);
                   let newVouchers = {selectedId: vouchers.selectedId, vouchers: [..._vouchers]};
                   dispatch(siteActions.vouchers(newVouchers));

               }
            });

        }
    }

    return <div className="row-block">
        <p className="mobile subtitle">{T('sent_vouchers')}</p>

        <div className="row head desktop">
            <div className="col-md-3">{T('ticket_type')}</div>
            <div className="col-md-3">{T('name')}</div>
            <div className="col-md-3">{T('email')}</div>
            <div className="col-md-3">{T('sent_quantity')}</div>
        </div>
        <div className="filter-holder">
            <div className="filter-open mobile" onClick={() => setOpen(!open)}>
                {T('edit_filters')}&nbsp;
                {open && <FontAwesomeIcon icon={"fa-solid fa-chevron-up"}/>}
                {!open && <FontAwesomeIcon icon={"fa-solid fa-chevron-down"}/>}
            </div>
            <Collapse in={open}>
                <div>
                    <div className="mobile">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-2">
                                <label className="mobile">{T('name')}</label>
                                <input onChange={handleNameInput} ref={nameRef} type="text"/></div>
                            <div className="col-md-2">
                                <label className="mobile">{T('email')}</label>
                                <input onChange={handleEmailInput} ref={emailRef} type="text"/>
                            </div>
                            <div className="col-md-2"></div>

                        </div>
                    </div>
                </div>
            </Collapse>
            <div className="row desktop">
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <input onChange={handleNameInput} ref={nameRef} type="text"/></div>
                <div className="col-md-3">
                    <input onChange={handleEmailInput} ref={emailRef} type="text"/>
                </div>
                <div className="col-md-3"></div>

            </div>
        </div>
        {filteredVouchers.map((voucher, i) => <SentRow key={i + 'sent_voucher'} voucher={voucher}
                                                       handleDeleteVoucher={handleDeleteVoucher} handleDeleteTicket={handleDeleteTicket}/>)}
    </div>;
}
