import React, {useEffect, useState} from "react";

import EventItem from "./EventItem";
import EventModel from "../../models/Event";

export default (props) => {
    const [events, setEvents] = useState([]);
    useEffect(()=>{
        EventModel.getAll().then((futureEvents) => {
            setEvents(futureEvents);
        });
    },[]);
    return <>
        <h2 className="title">Közelgő események</h2>
        <div className="row">
            {!!events && events.map((event, index) => <div key={index + "_event"} className="col-sm-3"><EventItem
                event={event}/></div>)}
        </div>
    </>;
}